//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "SizeTableInternational",
    mixins: [
        breakpointsListMixin,
    ],
    data: function () {
        return {
            tableData: {
                head: {
                    centimeter: ["UA", "42", "44", "46", "48", "50", "52", "54", "56", "58", "60", "62", "64", "66"],
                },
                body: {
                    centimeter: [
                        ["UK", "26\"", "28\"", "30\"", "32\"", "34\"", "36\"", "38\"", "40\"", "42\"", "44\"", "46\"", "48\"", "50\""],
                        ["US", "26\"", "28\"", "30\"", "32\"", "34\"", "36\"", "38\"", "40\"", "42\"", "44\"", "46\"", "48\"", "50\""],
                        ["FR", "36", "38", "40", "42", "44", "46", "48", "50", "52", "54", "56", "58", "60"],
                        ["IT", "42", "44", "46", "48", "50", "52", "54", "56", "58", "60", "62", "64", "66"],
                    ]
                }
            }
        }
    },

    computed: {
        tableHeadData() {
            return this.tableData.head.centimeter
        },
        tableBodyData() {
            return this.tableData.body.centimeter
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }

}
