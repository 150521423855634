//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "SizeTableStandart",
    mixins: [
        breakpointsListMixin,
    ],
    data: function () {
        return {
            isInch: false,
            tableData: {
                head: {
                    inch:       [this.$t('sizeTable.unitefitBottom.collName'), "XS", "S", "M", "L", "XL", "2XL", "3XL"],
                    centimeter: [this.$t('sizeTable.unitefitBottom.collName'), "XS", "S", "M", "L", "XL", "2XL", "3XL"],
                },
                body: {
                    inch: [
                        [this.$t('sizeTable.unitefitBottom.rows.row1'), "27 1/2 - 29\"", "29 1/2 - 31 1/2\"", "32 - 34 1/2\"", "35 - 38\"", "38 1/2 - 42\"", "42 1/2 - 47\"", "47 1/2 - 52\""],
                        [this.$t('sizeTable.unitefitBottom.rows.row2'), "32 - 33 1/2\"", "34 - 36\"", "36 1/2 - 39\"", "39 1/2 - 42\"", "42 1/2 - 45 1/2\"", "46 - 49\"", "49 1/2 - 53\""],
                        [this.$t('sizeTable.unitefitBottom.rows.row3'), "32\"", "32\"", "32\"", "32 1/2\"", "32 1/2\"", "32 1/2\"", "32\""],
                    ],
                    centimeter: [
                        [this.$t('sizeTable.unitefitBottom.rows.row1'), "71 - 74 см", "75 - 80 см", "81 - 88 см", "89 - 96 см", "97 - 106 см", "107 - 119 см", "120 - 132 см"],
                        [this.$t('sizeTable.unitefitBottom.rows.row2'), "82 - 85 см", "86 - 91 см", "92 - 99 см", "100 - 107 см", "108 - 116 см", "117 - 125 см", "126 - 135 см"],
                        [this.$t('sizeTable.unitefitBottom.rows.row3'), "81 см", "81.5 см", "82 см", "82.5 см", "83см", "82.5см", "82 см"],
                    ]
                }
            }
        }
    },

    computed: {
        tableHeadData() {
            return this.isInch ? this.tableData.head.inch : this.tableData.head.centimeter
        },
        tableBodyData() {
            return this.isInch ? this.tableData.body.inch : this.tableData.body.centimeter
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }

}
