//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "SizeTableStandart",
    mixins: [
        breakpointsListMixin,
    ],
    data: function () {
        return {
            isInch: false,
            tableData: {
                head: {
                    inch:       [this.$t('sizeTable.unitefitBottom.collName'), "42", "44", "46", "48", "50", "52", "54", "56", "58", "60", "62", "64", "66"],
                    centimeter: [this.$t('sizeTable.unitefitBottom.collName'), "42", "44", "46", "48", "50", "52", "54", "56", "58", "60", "62", "64", "66"],
                },
                body: {
                    inch: [
                        [this.$t('sizeTable.unitefitBottom.rows.row1'), "28\" - 29\"", "29 1/2\" - 30 1/2\"", "31\" - 31 1/2\"", "32\" - 33\"", "33 1/2\" - 34 1/2\"", "35\" - 36\"", "36 1/2\" - 38\"", "38 1/2\" - 40\"", "40 1/2\" - 42\"", "42 1/2\" - 44 1/2\"", "45\" - 47\"", "47 1/2\" - 49 1 / 2\"", "48\" - 52\""],
                        [this.$t('sizeTable.unitefitBottom.rows.row2'), "32 1/2\" - 33 1/2\"", "34\" - 34 1/2\"", "35\"- 36\"", "36 1/2\" - 37 1/2\"", "38\" - 39\"", "39 1/2\" - 40 1/2\"", "41\" - 42\"", "42 1/2\" - 44\"", "44 1/2\" - 45 1/2\"", "46\" - 47 1/2\"", "48\" - 49\"", "49 1/2\" - 51\"", "50\" - 53\""],
                        [this.$t('sizeTable.unitefitBottom.rows.row3'), "32\"", "32\"", "32\"", "32\"", "32\"", "32 1/2\"", "32 1/2\"", "32 1/2\"", "32 1/2\"", "32 1/2\"", "32 1/2\"", "32 1/2\"", "32\""],
                    ],
                    centimeter: [
                        [this.$t('sizeTable.unitefitBottom.rows.row1'), "71.5 - 74", "74.5 - 77", "77.5 - 80", "80.5 - 84", "84.5 - 88", "88.5 - 92", "92.5 - 96", "96.5- 101", "101.5 - 106", "106.5 - 112.5", "113 - 119", "119.5 - 125.5", "126 - 132"],
                        [this.$t('sizeTable.unitefitBottom.rows.row2'), "82.5 - 85", "85.5 - 88", "88.5 - 91", "91.5 - 95", "95.5 - 99", "99.5 - 103", "103.5 - 107", "107.5 - 111.5", "112 - 116", "116.5 - 120.5", "121 - 125", "125.5 - 130", "130.5 - 135"],
                        [this.$t('sizeTable.unitefitBottom.rows.row3'), "81", "81,5", "81,5", "82", "82", "82,5", "82,5", "83", "83", "83", "82,5", "82,5", "82"],
                    ]
                }
            }
        }
    },

    computed: {
        tableHeadData() {
            return this.isInch ? this.tableData.head.inch : this.tableData.head.centimeter
        },
        tableBodyData() {
            return this.isInch ? this.tableData.body.inch : this.tableData.body.centimeter
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }

}
