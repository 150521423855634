//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "../../../../common/ResponsiveImage";
import SizeTableStandart from "./components/SizeTable/SizeTableStandart.vue";
import SizeTableStandartNumber from "./components/SizeTable/SizeTableStandartNumber.vue";
import SizeTableInternational from "./components/SizeTable/SizeTableInternational.vue";

export default {
    name: "SizeUnitefitBottoms",
    data: function() {
        return {
            bottomImage: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/10/8e68c3c7bf14ad0bcaba52babfa470bd/64abfcffeda27/da29.svg',
        }
    },
    computed: {
        imageSrc() {
            return this.isWomenSwimsuits ? this.swimsuitsImage : this.clothesImage;
        },
        l () {
            return this.$i18n.locale
        }
    },
    methods: {
        scrollTo(el) {
            return {
                el: el,
                container: '.v-popup__container__body',
                duration: 300,
                lazy: false,
                easing: 'linear',
                cancelable: true,
                x: false,
                y: true
            }
        },
        imageConfig(url, title = "", alt = "") {
            return {
                src: {
                    desktop: {
                        '1x': url,
                        '2x': url,
                    },
                    tablet: {
                        '1x': url,
                        '2x': url,
                    },
                    mobile: {
                        '1x': url,
                        '2x': url,
                    },
                },
                title: title,
                alt: alt,
            }
        },
    },
    components: {
        SizeTableInternational,
        SizeTableStandart,
        SizeTableStandartNumber,
        ResponsiveImage
    },
}
